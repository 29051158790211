@use "@/styles/constants/devices.scss" as *;

.headToHeadRow {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 45%;
  margin-bottom: 1rem;
  padding-right: 0;
  text-align: left;
  align-items: center;

  @media only screen and (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    flex: unset;
    width: 47.5%;
    margin: 0 auto 1rem 0;
    padding-right: 1rem;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.competitionBadge {
  margin-right: auto;
  display: flex;
  align-items: center;
  flex: 1 1 0;
}

.teamBadgeHome {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
}

.teamBadgeAway {
  display: flex;
  margin-left: 1rem;
  margin-right: auto;
}

.score {
  background-color: var(--inactiveElement);
  color: var(--defaultText);
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  place-content: center;
  padding: 0.5rem;
  width: 24px;

  &.home {
    border-radius: 4px 0 0 4px;
  }

  &.away {
    border-radius: 0 4px 4px 0;
  }

  &.win {
    color: var(--matchOverview-winnerSolidColor);
    background-color: var(--matchOverview-winnerFadedColor);
  }
}

.date {
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  font-size: 0.5rem;
  font-weight: 500;
  margin-left: auto;
}
