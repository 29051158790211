@use "@/styles/constants/devices.scss" as *;

.tab__button-container {
  display: flex;
  width: 100%;
  padding-top: 1px;
}

.tab__button {
  background-color: var(--tab-inactiveTabColor);
  border: none;
  font-weight: 700;
  font-size: 10px;
  margin-right: 1px;
  flex: 1 1 0;
  padding: 1rem;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  color: var(--tab-defaultText);
  &[aria-pressed="true"] {
    color: var(--tab-activeText);
    background-color: var(--tab-activeTabColor);
  }

  @media only screen and (min-width: $tablet) {
    font-size: 12px;
  }
}

.remove_margin {
  margin-right: 0;
}

.no_underline {
  // styling for active state but not activeUnderline
  &[aria-pressed="true"] {
    background-color: var(--tab-activeTabColor);
  }
}

.underline {
  // styling for inactive state but activeUnderline
  &[aria-pressed="false"] {
    background-color: var(--betslip-background);
    border-bottom: 2px solid var(--betslip-borderBottomColor);
    font-weight: 500;
    color: var(--tab-underlineFontColor);
  }
  // styling for active state and activeUnderline
  &[aria-pressed="true"] {
    border-bottom: 2px solid var(--tab-activeTabUnderlineColor);
  }
}

.tab__content-container {
  background-color: var(--betType-backgroundColor);
}
