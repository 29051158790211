.button {
  flex: 0 1 60px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: var(--buttonPill-inactiveBackgroundColor);
  color: var(--buttonPill-inactiveFontColor);
  border-radius: 1rem;
  margin: 0px 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  &[aria-pressed="true"] {
    background-color: var(--buttonPill-activeBackgroundColor);
    color: var(--buttonPill-activeFontColor);
  }
}
