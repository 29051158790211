@use "@/styles/constants/devices.scss" as *;

.buttonGroup {
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 0 1rem;
}

.matchOverviewSpecialsContainer {
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: 500;

  @media only screen and (min-width: $laptop) {
    display: flex;
    flex-flow: row wrap;
  }
}

.matchOverviewSpecial {
  display: flex;
  flex-direction: column;
  margin: 0px 0.5rem 1rem 0px;
  padding: 1rem;
  border-radius: 8px;
  word-break: break-word;

  @media only screen and (min-width: $laptop) {
    width: calc(50% - 0.5rem);
  }
}

.bookmaker {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 1rem;
}

.bookmakerName {
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-left: 0.5rem;
}

.bookmakerLink {
  max-width: 60px;
  font-size: 0.625rem;
  font-weight: 700;
  padding: 0.75rem 0.75rem;
  border-radius: 4px;
  text-align: center;
  margin-top: auto;
  text-decoration: none;
}

.toggleContainer {
  border-top: 1px solid var(--buttonPill-borderColor);
  padding: 10px 0;

  button {
    background-color: transparent;
    border: none;
    color: var(--buttonPill-toggleFontColor);
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 0.5rem 0;
  }

  @media only screen and (min-width: $mobile) {
    button:hover {
      text-decoration: underline;
    }
  }
}
