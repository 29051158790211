@use "@/styles/constants/devices.scss" as *;

.metaContainer {
  height: 136px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-color: var(--contentBackground);

  @media only screen and (min-width: $laptop) {
    border-radius: 8px 8px 0 0;
    height: 310px;
  }
}

.scoreboard {
  padding: 32px 16px 0;
}

.meta {
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: $laptop) {
    flex-direction: column;
  }
}

.state {
  display: flex;
  flex-basis: 1.75rem;
  justify-content: center;

  @media only screen and (min-width: $laptop) {
    padding: 0.5rem;
  }
}

.competition {
  margin: 0;
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 13.8px;
  color: var(--matchOverview-competitionFontColor);

  @media only screen and (min-width: $laptop) {
    font-size: 1.125rem;
    text-align: center;
  }
}

.teamsAndScores {
  padding-top: 0.5rem;

  @media only screen and (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1.5rem 0;
  }
}

.vsSpan {
  font-size: 1.5rem;
  font-weight: 700;
  align-self: center;
  display: none;

  @media only screen and (min-width: $laptop) {
    display: inline;
  }
}

.teamAndScore {
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: $laptop) {
    flex-direction: row;

    &.away {
      flex-direction: row-reverse;
    }
  }
}

.team {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  @media only screen and (min-width: $laptop) {
    flex-direction: column;
  }
}

.teamBadgeContainer {
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: $laptop) {
    width: 110px;
    height: 110px;
    margin-bottom: 0.5rem;
  }
}

.teamName {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  padding-left: 0.5rem;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.1px;
}

.goal {
  display: flex;
  color: var(--matchOverview-scoreText);
  background-color: var(--matchOverview-scoreBackground);
  text-align: center;
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  flex-basis: 0.75rem;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  min-width: 24px;

  &.home {
    border-radius: 4px 4px 0 0;
  }

  @media only screen and (min-width: $laptop) {
    margin: 1rem;
    flex-grow: 2;
    width: 2rem;
    height: 2rem;
    border-radius: 4px 4px 4px 4px;
    align-self: center;
    align-items: center;
    min-width: initial;
  }
}

.form {
  align-items: center;
  justify-content: center;
  padding: 0;
  display: none;

  &.fixture {
    display: flex;
  }

  @media only screen and (min-width: $laptop) {
    display: flex;
  }
}

.preMatchTeamLineupContainer {
  text-align: left;
}

.transparentOverlay {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: var(--matchOverview-overlay);
  border-radius: unset;

  @media only screen and (min-width: $laptop) {
    border-radius: 8px 8px 0 0;
  }
}
