@use "@/styles/constants/devices.scss" as *;

.insightsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.insight {
  margin: 1rem;
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  background-color: var(--matchOverviewInsights-backgroundColor);
  border: 1px solid var(--matchOverviewInsights-borderColor);
  border-radius: 0.5rem;
}

.insightSeparator {
  margin: 0 1rem;
  border-top: 1px dashed var(--matchOverviewInsights-separator);
  border-bottom: none;
  border-right: none;
  border-left: none;
}

.insightsButtonContainer {
  border-top: 1px solid var(--buttonPill-borderColor);
  padding: 10px 0;

  button {
    background-color: transparent;
    border: none;
    color: var(--buttonPill-toggleFontColor);
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 0.5rem 0;
  }

  @media only screen and (min-width: $mobile) {
    button:hover {
      text-decoration: underline;
    }
  }
}
