@use "@/styles/constants/devices.scss" as *;

.percentageStatPairsContainer {
  @media only screen and (min-width: $laptop) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
}

.bookingStatPairsContainer {
  display: flex;
  justify-content: space-between;
}
