.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 80px;
  background-color: var(--inactiveElement);
  padding: 1.25rem 0.5rem;
  margin: 0 0.25rem 0.5rem;
  border-radius: 0.25rem;

  h4 {
    font-size: 0.5rem;
    font-weight: normal;
    margin: 0;
  }

  p {
    font-size: 0.75rem;
    margin: 0.25rem 0;
  }
}

.positiveStatColor {
  color: var(--matchOverview-winnerSolidColor);
}
