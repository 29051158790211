@use "@/styles/constants/devices.scss" as *;

.container {
  display: flex;
  flex-flow: row wrap;

  @media only screen and (min-width: $laptop) {
    flex-wrap: nowrap;
  }
}
