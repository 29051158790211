.container {
  display: block;
  font-size: 0.625rem;
  color: var(--defaultText);
  background-color: var(--contentBackground);
  margin-bottom: 1rem;
  text-align: center;
  padding: 1rem;

  h3 {
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--defaultText);
    margin-bottom: 1rem;
    text-align: center;
  }
}
