@use "@/styles/constants/devices.scss" as *;

.result {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 45%;
  margin-bottom: 1rem;
  padding-right: 1rem;
  text-align: left;

  @media only screen and (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    flex: unset;
    width: 47.5%;
    align-items: center;
    margin: 0 auto 1rem 0;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.badgeContainer {
  position: relative;
  margin-right: 1rem;
}

.competitionBadgeContainer {
  position: absolute;
  right: -5px;
  bottom: -1px;
}

.metaContent {
  font-size: 0.5rem;
  color: var(--formResultMetaFontColor);
  margin-bottom: 0.25rem;
  box-sizing: border-box;
}

.opponentName {
  font-size: 0.6125rem;
  margin: 0;
}

.outcome {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  height: 29px;
  width: 27px;
  border-radius: 4px;
  margin: 0 0.5rem 0 auto;
  box-sizing: border-box;

  &--W {
    background-color: var(--matchOverview-winnerFadedColor);
    color: var(--matchOverview-winnerSolidColor);
  }

  &--D {
    background-color: var(--matchOverview-drawFadedColor);
    color: var(--matchOverview-drawSolidColor);
  }

  &--L {
    background-color: var(--matchOverview-loserFadedColor);
    color: var(--matchOverview-loserSolidColor);
  }
}

.scoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--inactiveElement);
  border-radius: 0.25rem;

  p {
    margin: 0;
    width: 26px;
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;
  }

  @media only screen and (min-width: $laptop) {
    height: 100%;
  }
}
