@use "@/styles/constants/devices.scss" as *;

.desktopOnlySpan {
  display: none;
  color: var(--matchOverview-competitionFontColor);

  @media only screen and (min-width: $laptop) {
    display: inline;
  }
}

.mobileAndTabletSpan {
  @media only screen and (min-width: $laptop) {
    display: none;
  }
}
