@use "@/styles/constants/devices.scss" as *;

.headToHeadRowContainer {
  @media only screen and (min-width: $laptop) {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}
